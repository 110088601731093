@import '../../scss/mixins';
@import '../../custom.scss';

// .max-1080 {
//   max-width: 1720px;
//   margin: 0 auto;
// }
// .my-selector {
//   position: relative;
//   video {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }
.hero {
  position: relative;
  height: 75vh; /* Set the height of the hero to 75% of the viewport height */

  @include mq-m($mobile-lg) {
    height: 75vh;
  }

  video,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-section {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25vh; /* Set the height of the text section to 25% of the viewport height */
    background-color: #fff; /* Set the background color of the text section */
    /* Add any other styles you want for the text section */

    @include mq-m($mobile-lg) {
      height: 25vh;
    }
  }
}
.mobile-cool-container,
.mobile-cool,
.mobile-cool2 {
  width: 50%;
}

.mobile-cool {
  justify-content: center;
}

@include mq-m($mobile) {
  .mobile-cool-container {
    width: 100%;
  }
  .mobile-cool {
    width: 70% !important;
    justify-content: flex-start;
  }

  .mobile-cool2 {
    width: 30% !important;
  }
}

.gh {
  margin-right: 2rem;

  @include mq-m($mobile-lg) {
    margin-right: 0;
  }
}

.video {
  filter: invert(100%);
}

.feat {
  border-bottom: 4px solid black;
}

.contact-container {
  border: 1px solid white;
  border-radius: 10px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ct {
  background-image: url('https://minters.s3.us-west-2.amazonaws.com/11111.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
}

#about {
  @include mq-m($mobile) {
    flex-direction: column !important;

    .about-section {
      width: 100% !important;
    }
  }
}

.mobile-hero {
  display: none;
  width: 100%;
  @include mq-m($mobile-lg) {
    display: block;
  }
}
.desktop-hero {
  display: block;
  width: 100%;
  @include mq-m($mobile-lg) {
    display: none;
  }
}

.cont {
  padding: 0 2rem 2rem 2rem;
  @include mq-m($mobile-lg) {
    padding: 0 1rem 1rem 1rem;
  }
}

.about-section {
  canvas {
    overflow: hidden;
    @include mq-m($mobile-lg) {
      height: 400px !important;
      margin-bottom: 40px !important;
    }
  }
}

#work {
  @include mq-m($mobile-lg) {
    padding: 0.4rem !important;
  }
}

.test {
  overflow-x: hidden;
}
