.accordion {
  margin-bottom: 1rem;
  &:first-child {
    border-top: 2px solid #000000;
  }
  .accordion-header {
    cursor: pointer;
    padding: 0.75rem 1.25rem;
    background-color: #ffffff;
    border-bottom: 2px solid #000000;

    &:hover {
      background-color: var(--primary);
    }

    button {
      color: #212529;
      font-weight: 600;
      text-align: left;
      width: 100%;
    }
  }

  .accordion-body {
    padding: 1.25rem;
    border-top: 1px solid #dee2e6;
  }
}
