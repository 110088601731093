@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;1,600;1,700&family=Ubuntu+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
/* * *
* DEVICES
* */

$mobile: 400px;
$mobile-lg: 520px;
$tablet: 768px;
$desktop-sm: 992px;
$desktop: 1200px;
$desktop-lg: 1380px;
$desktop-max: 1504px;

@mixin mq($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (min-width: $value) {
    @content;
  }
}
/* * *
* for setting styles based on
* max width. prefer min-width (mq)
* */
@mixin mq-m($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (max-width: $value) {
    @content;
  }
}

// Override default variables before the import
$theme-colors: (
  'primary': rgb(3, 98, 130),
  //'primary': #86d9bf,
  'danger': #ff4136,
  'orange': #ffa136,
  'black': #000000,
  'grey': #d9d9d9,
  'white': #ffffff,
  'light-blue': #6ec8fe,
  'navy': rgb(26, 34, 40),
  'blue': rgb(62, 141, 185),
  'hellothere': rgb(180, 74, 150),
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
* {
  margin: 0 !important;
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style-type: none;
}

.text-lg-h1,
.text-lg-h2,
.text-lg-h3,
.text-lg-h4,
.text-lg-h5 {
  font-family: 'Roboto', sans-serif;
  line-height: 1;
}

.text-lg-p1,
.text-lg-p2 {
  font-family: 'Roboto', sans-serif;
}

.text-lg-h1 {
  font-weight: 500;
  font-size: 180px;

  @include mq-m(1065) {
    font-size: 160px;
  }

  @include mq-m(950) {
    font-size: 140px;
  }
  @include mq-m(400) {
    font-size: 60px;
  }
}

.text-lg-h2 {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.3;

  @include mq-m(1080) {
    font-size: 32px;
  }

  @include mq-m(950) {
    font-size: 28px;
  }
  @include mq-m(400) {
    font-size: 22px;
  }
}

.text-lg-h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;

  @include mq-m(1285) {
    font-size: 26px;
  }

  @include mq-m(1175) {
    font-size: 22px;
  }

  @include mq-m(950) {
    font-size: 20px;
  }
}

.text-lg-h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

.text-lg-modal {
  font-size: 75px;
  font-weight: 700;
  line-height: 1.3;

  @include mq-m(1135) {
    font-size: 55px;
  }
}

.text-lg-p1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

.text-lg-p2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.fixed {
  position: fixed;
}

.indent-75 {
  text-indent: 75px;
}

.indent-150 {
  text-indent: 175px;
}

.hero-bg-img {
  background-image: url('http://localhost:3000/images/pawel-czerwinski-g2Zf3hJyYAc-unsplash.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.clear {
  background-color: rgba(0, 0, 0, 0);
}

.no-click {
  pointer-events: none;
}

.clickable {
  pointer-events: all !important;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.z1000 {
  z-index: 1000;
}

.z8 {
  z-index: 8;
}
.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}
.z5 {
  z-index: 5;
}
.z6 {
  z-index: 6;
}

html,
body {
  overscroll-behavior: none !important;
}

.bw-bottom {
  border-bottom: 1px solid white;
}

.bw-top {
  border-top: 1px solid white;
}

.bw-left {
  border-left: 1px solid white;
}

.bw-right {
  border-right: 1px solid white;
}

.btn-rounded {
  border-radius: 50px;
}

.underline {
  text-decoration: underline;
}

.underline:focus {
  box-shadow: none;
  border: 0;
}

.no-underline,
.no-underline:hover,
.no-underline:focus,
.no-underline:visited {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.open > .dropdown-toggle.btn-outline-white {
  color: #000 !important;
  background-color: rgb(3, 98, 130) !important;
  border-color: rgb(3, 98, 130) !important; /*set the color you want here*/
}

a.text-white:hover {
  color: rgb(3, 98, 130) !important;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.mono {
  font-family: 'Roboto Mono', monospace !important;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.h-15 {
  height: 15%;
}

.h-20 {
  height: 20%;
}

.h-25 {
  height: 25%;
}

.h-30 {
  height: 30%;
}

.h-35 {
  height: 35%;
}

.h-40 {
  height: 40%;
}

.h-45 {
  height: 45%;
}

.h-50 {
  height: 50%;
}

.h-55 {
  height: 55%;
}

.h-60 {
  height: 60%;
}

.h-65 {
  height: 65%;
}
.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}
.h-80 {
  height: 80%;
}

.h-85 {
  height: 85%;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100%;
}

.project-pill {
  width: 125px;
  border: 2px solid black;
  font-weight: 700;
}

.hero-test {
  display: flex;

  @include mq-m(850) {
    flex-direction: column-reverse;
  }
}

.hide-mobile {
  @include mq-m(850) {
    display: none;
  }
}
.hide-on-mobile {
  @include mq-m($mobile-lg) {
    display: none !important;
  }
}

.player-2 {
  font-family: 'Press Start 2P', cursive !important;
}

.lh-2 {
  line-height: 1.2 !important;
}
