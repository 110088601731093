@import '../../custom.scss';
.modal-container {
  background-color: white;
  z-index: 10;
  position: fixed;
  border: 2px solid black;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  transition: left 0.6s ease-in-out;

  &.closed {
    top: 0;
    left: -100vw;
  }
}

.project-container {
  display: flex;

  @include mq-m(900) {
    flex-direction: column;
  }
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  color: black;
  font-size: 33px;

  @include mq-m(900) {
    &.hello {
      color: white;
    }
  }
}

.photo-container,
.text-container {
  width: 50%;
  height: 100%;

  @include mq-m(900) {
    width: 100%;
    height: 50%;
  }
}

.text-container {
  overflow-y: scroll;
}

.dtails {
  hr {
    border-bottom: solid 2px black;
  }
}


