.arrow-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.arrow-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.modal-image {
  max-height: 95%;
}
