@import '../../scss/mixins';
@import '../../custom.scss';

.p-card {
  border: 2px solid black;
  border-radius: 10px;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @include mq-m($mobile) {
      min-height: 0;
    }
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
